<template>
  <a-card
    :loading="loading"
    :bordered="false"
    :bodyStyle="{
      width: '100%',
      color: '#444'
    }"
  >
    <slot name="header"></slot>
    <div
      :v-if="!loading"
      :id="id"
      :style="`height:${height}px;width:100%;`"
    ></div>
  </a-card>
</template>

<script>
import { Chart } from "@antv/g2";

const defaultData = () => [
  { type: "未知", value: 654, percent: 0.02 },
  { type: "17 岁以下", value: 654, percent: 0.02 },
  { type: "18-24 岁", value: 4400, percent: 0.2 },
  { type: "25-29 岁", value: 5300, percent: 0.24 },
  { type: "30-39 岁", value: 6200, percent: 0.28 },
  { type: "40-49 岁", value: 3300, percent: 0.14 },
  { type: "50 岁以上", value: 1500, percent: 0.06 }
];

export default {
  name: "ColumnChart",

  props: {
    loading: Boolean,
    title: { type: String, required: false },
    height: { type: Number, default: 300 },
    data: {
      type: Array,
      default: defaultData
    },
    typeName: { type: String, default: "type" },
    valueName: { type: String, default: "value" },
    valueAlias: { type: String, default: "value" },
    color: { type: String, default: "rgb(126,181,155)" },
    id: String
  },

  updated() {
    this.drawChart();
  },

  methods: {
    drawChart: function() {
      if (this.loading) {
        this.chart && this.chart.destroy();
      } else {
        if (this.chart === undefined || this.chart.destroyed) {
          this.chart = new Chart({
            container: this.id,
            autoFit: true,
            height: this.height
          });

          this.chart.data(this.data);

          const margin = 1 / this.data.length;
          this.chart.scale(this.typeName, {
            range: [margin / 2, 1 - margin / 2]
          });

          this.chart.scale(this.valueName, {
            alias: this.valueAlias
          });

          this.chart.axis(this.typeName, {
            label: {
              rotate: -0.3,
              offset: 20,
              autoRotate: false,
              autoHide: false
            }
          });

          this.chart
            .interval()
            .position(`${this.typeName}*${this.valueName}`)
            .color(this.color);

          this.chart.interaction("active-region");

          this.chart.render();
        }
      }
    }
  }
};
</script>
