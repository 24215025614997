<template>
  <div>
    {{ label + ":" }}
    <span :class="value > 0 ? 'flag-up' : value < 0 ? 'flag-down' : ''">
      {{ value }}
      {{ percentage ? "%" : "" }}
    </span>
  </div>
</template>

<script>
export default {
  name: "Trend",
  props: {
    label: {
      type: String,
      default: "",
      required: true
    },
    percentage: {
      type: Boolean,
      default: true
    },
    value: {
      type: [Number, String],
      default: "-"
    }
  }
};
</script>

<style lang="css">
.flag-up {
  color: green;
  position: relative;
  margin-left: 16px;
}

.flag-up::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: -14px;
  bottom: 4px;
  border: 6px solid #fff;
  border-bottom: 9px solid green;
}

.flag-down {
  color: red;
  position: relative;
  margin-left: 16px;
}

.flag-down::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  top: 5px;
  left: -14px;
  border: 6px solid #fff;
  border-top: 9px solid red;
}
</style>
