<template>
  <a-card
    :loading="loading"
    :title="city"
    :bodyStyle="{
      display: 'flex',
      flexDirection: 'column',
      color: '#444'
    }"
  >
    <div
      :id="id"
      :style="'height:' + height + 'px'"
      class="city-card-chart"
    ></div>
    <div>计租厨房 / 签约厨房 / 运营中厨房</div>
    <div>
      <span style="font-size:28px;font-weight:800;">{{
        payingKitchenCount
      }}</span>
      <span style="font-size:16px;font-weight:800;"
        >/ {{ soldKitchenCount }}</span
      >
      <span style="font-size:16px;font-weight:800;">/ {{ kitchenCount }}</span>
    </div>
    <div>平均租金 / 平均入会费</div>
    <div>
      <span style="font-size:28px;font-weight:800;"
        >¥{{ Number.parseInt(avgRent).toLocaleString() }}</span
      >
      <span style="font-size:20px;font-weight:800;"
        >/ ¥{{ Number.parseInt(avgEntryFee).toLocaleString() }}</span
      >
    </div>
  </a-card>
</template>

<script>
import { Chart } from "@antv/g2";

const colorMap = {
  Sold: "rgb(126,181,155)",
  Booked: "rgb(207,243,224)",
  Unfilled: "rgb(240,240,240)"
};

export default {
  name: "CityCard",

  props: {
    loading: Boolean,
    city: String,
    id: String,
    payingKitchenCount: Number,
    soldKitchenCount: Number,
    kitchenCount: Number,
    avgRent: Number,
    avgEntryFee: Number,
    height: Number
  },

  data() {
    return {};
  },

  mounted() {
    this.drawChart();
  },

  methods: {
    drawChart: function() {
      this.chart && this.chart.destory();
      this.chart = new Chart({
        container: this.id,
        autoFit: true,
        height: this.height
      });

      this.chart.data(this.getData());
      this.chart.scale("percent");
      this.chart.coordinate("theta", {
        radius: 0.7,
        innerRadius: 0.75
      });

      let soldRate = 0;
      if (this.kitchenCount > 0) {
        soldRate = (
          (this.payingKitchenCount * 100) /
          this.kitchenCount
        ).toFixed(1);
      }

      this.chart.annotation().text({
        position: ["50%", "50%"],
        content: soldRate + "%",
        style: {
          fontSize: 24,
          fontWeight: 900,
          fill: "#444",
          textAlign: "center"
        }
      });
      this.chart
        .interval()
        .adjust("stack")
        .position("percent")
        .color("item", val => colorMap[val]);
      this.chart.legend(false);
      this.chart.tooltip(false);
      this.chart.render();
    },

    getData: function() {
      return [
        {
          item: "Sold",
          count: this.payingKitchenCount,
          percent:
            this.kitchenCount > 0
              ? this.payingKitchenCount / this.kitchenCount
              : 0
        },
        {
          item: "Booked",
          count: this.soldKitchenCount,
          percent:
            this.kitchenCount > 0
              ? (this.soldKitchenCount - this.payingKitchenCount) /
                this.kitchenCount
              : 0
        },
        {
          item: "Unfilled",
          count: this.kitchenCount - this.soldKitchenCount,
          percent:
            this.kitchenCount > 0
              ? (this.kitchenCount - this.soldKitchenCount) / this.kitchenCount
              : 0
        }
      ];
    }
  }
};
</script>
