<template>
  <a-card
    :loading="loading"
    :bordered="false"
    :bodyStyle="{
      display: 'flex',
      flexDirection: 'column',
      color: '#444'
    }"
  >
    <slot name="header"></slot>
    <div
      v-if="title"
      style="font-size:14px;color:rgba(0,0,0,.5);,line-height:22px"
    >
      {{ title }}
    </div>
    <div :v-if="!loading" :id="id" :style="'height:' + height + 'px'"></div>
  </a-card>
</template>

<script>
import { Chart } from "@antv/g2";

const defaultData = () => [
  { type: "1991", value: 15468 },
  { type: "1992", value: 16100 },
  { type: "1993", value: 15900 },
  { type: "1994", value: 17409 },
  { type: "1995", value: 17000 },
  { type: "1996", value: 31056 },
  { type: "1997", value: 31982 },
  { type: "1998", value: 32040 },
  { type: "1999", value: 33233 }
];

export default {
  name: "AreaChart",

  props: {
    loading: Boolean,
    title: { type: String, required: false },
    height: { type: Number, default: 300 },
    data: {
      type: Array,
      default: defaultData
    },
    typeName: { type: String, default: "type" },
    valueName: { type: String, default: "value" },
    valueAlias: { type: String, default: "value" },
    valueFormatter: { type: Function, default: null },
    id: String
  },

  updated() {
    this.drawChart();
  },

  methods: {
    drawChart: function() {
      if (this.loading) {
        this.chart && this.chart.destroy();
      } else {
        if (this.chart === undefined || this.chart.destroyed) {
          this.chart = new Chart({
            container: this.id,
            autoFit: true,
            height: this.height,
            padding: [50, 20, 50, 50]
          });
          this.chart.data(this.data);

          const margin = 1 / this.data.length;
          this.chart.scale(this.typeName, {
            range: [margin / 5, 1 - margin / 5]
          });

          this.chart.scale(this.valueName, {
            alias: this.valueAlias,
            formatter: this.valueFormatter
          });

          this.chart.axis(this.typeName, {
            label: {
              rotate: -0.3,
              offset: 20,
              autoRotate: false,
              autoHide: false
            }
          });

          this.chart.tooltip({
            showCrosshairs: true
          });

          this.chart
            .area()
            .shape("smooth")
            .style({
              fillOpacity: 0.75
            })
            .position(`${this.typeName}*${this.valueName}`)
            .color("rgb(126,181,155)");

          this.chart.render();
        }
      }
    }
  }
};
</script>
