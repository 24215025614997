<template>
  <div>
    <a-row>
      <a-col :xs="24" :lg="12" :xl="6">
        <chart-card
          :loading="loading"
          title="签约率"
          :total="data | nullsafe('soldKitchenOccupancy.value') | percent"
        >
          <template slot="trend">
            <trend label="月度同比" />
          </template>
          <template slot="footer">
            <div class="footer-left">
              已签约厨房数：{{
                data | nullsafe("soldKitchenCount") | NumberFormat
              }}
            </div>
            <trend label="月度同比" />
          </template>
        </chart-card>
      </a-col>
      <a-col :xs="24" :lg="12" :xl="6">
        <chart-card
          :loading="loading"
          title="计租率"
          :total="data | nullsafe('paidKitchenOccupancy.value') | percent"
        >
          <template slot="trend">
            <trend label="月度同比" />
          </template>
          <template slot="footer">
            <div class="footer-left">
              已计租厨房数：{{
                data | nullsafe("payingKitchenCount") | NumberFormat
              }}
            </div>
            <trend label="月度同比" />
          </template>
        </chart-card>
      </a-col>
      <a-col :xs="24" :lg="12" :xl="6">
        <chart-card
          :loading="loading"
          title="平均租金"
          :total="data | nullsafe('avgRent.value') | currency"
        >
          <template slot="trend">
            <trend label="月度同比" />
          </template>
          <template slot="footer">
            <div class="footer-left">
              平均入会费：{{ data | nullsafe("avgEntryFee.value") | currency }}
            </div>
            <trend label="月度同比" />
          </template>
        </chart-card>
      </a-col>
      <a-col :xs="24" :lg="12" :xl="6">
        <chart-card
          :loading="loading"
          title="运营中厨房数"
          :total="data | nullsafe('liveKitchenCount') | NumberFormat"
        >
          <template slot="trend">
            <trend label="月度同比" />
          </template>
          <template slot="footer">
            <div class="footer-left">
              运营中门店数：{{
                data | nullsafe("liveFacilityCount") | NumberFormat
              }}
            </div>
            <trend label="月度同比" />
          </template>
        </chart-card>
      </a-col>
    </a-row>
    <a-row style="margin-top:24px;">
      <a-col
        v-for="city in data.citysCoreMetricVOList"
        :key="city.id"
        :xs="24"
        :lg="12"
        :xl="6"
      >
        <city-card
          :loading="loading"
          :id="'city-' + city.cityId"
          :city="city.cityName"
          :height="200"
          :payingKitchenCount="city.coreMetricCityVO.payingKitchenCount.value"
          :soldKitchenCount="city.coreMetricCityVO.soldKitchenCount.value"
          :kitchenCount="city.coreMetricCityVO.liveKitchenCount.value"
          :avgRent="city.coreMetricCityVO.avgRent.value"
          :avgEntryFee="city.coreMetricCityVO.avgEntryFee.value"
        />
      </a-col>
    </a-row>
    <a-row>
      <a-card title="业务指标趋势" style="margin-top:24px;">
        <div slot="extra">
          <div>
            <a-radio-group
              :defaultValue="trendUnit"
              @change="onTrendUnitChange"
            >
              <a-radio-button value="Week">周度</a-radio-button>
              <a-radio-button value="Month">月度</a-radio-button>
            </a-radio-group>
          </div>
        </div>
        <a-row>
          <a-col :xs="24" :xl="12"
            ><column-chart
              :loading="chartLoading"
              typeName="period"
              style="border-right:1px solid #ddd"
              :data="chartData | nullsafe('closeWon.data', [])"
              valueAlias="新签数"
              id="close-won-trend"
            >
              <template slot="header">
                <div class="trend-title">新签数</div>
                <div>
                  <span class="trend-value">{{
                    chartData | nullsafe("closeWon.value", 0)
                  }}</span
                  ><span class="trend-mom"
                    >MoM<span
                      :class="chartData.closeWon.percentage > 0 ? 'up' : 'down'"
                    >
                      {{
                        chartData | nullsafe("closeWon.percentage", 0) | percent
                      }}</span
                    ></span
                  >
                </div>
              </template>
            </column-chart>
          </a-col>
          <a-col :xs="24" :xl="12"
            ><column-chart
              :loading="chartLoading"
              typeName="period"
              :data="chartData | nullsafe('churn.data', [])"
              color="rgb(228,123,123)"
              valueAlias="退租数"
              id="churn-trend"
            >
              <template slot="header">
                <div class="trend-title">退租数</div>
                <div>
                  <span class="trend-value">{{
                    chartData | nullsafe("churn.value", 0)
                  }}</span
                  ><span class="trend-mom"
                    >MoM<span
                      :class="chartData.churn.percentage <= 0 ? 'up' : 'down'"
                    >
                      {{
                        chartData | nullsafe("churn.percentage", 0) | percent
                      }}</span
                    ></span
                  >
                </div>
              </template>
            </column-chart>
          </a-col>
        </a-row>
        <a-row>
          <a-col :xs="24" :xl="12"
            ><area-chart
              :loading="chartLoading"
              style="border-right:1px solid #ddd;"
              typeName="period"
              :data="chartData | nullsafe('gmv.data', [])"
              :valueFormatter="mkFormatter"
              valueAlias="GMV"
              id="gmv-trend"
            >
              <template slot="header">
                <div class="trend-title">GMV</div>
                <div>
                  <span class="trend-value"
                    >¥
                    {{
                      chartData | nullsafe("gmv.value", 0) | shortNumber
                    }}</span
                  ><span class="trend-mom"
                    >MoM<span
                      :class="chartData.gmv.percentage > 0 ? 'up' : 'down'"
                    >
                      {{
                        chartData | nullsafe("gmv.percentage", 0) | percent
                      }}</span
                    ></span
                  >
                </div>
              </template>
            </area-chart>
          </a-col>
          <a-col :xs="24" :xl="12"
            ><area-chart
              :loading="chartLoading"
              typeName="period"
              :data="chartData | nullsafe('order.data', [])"
              :valueFormatter="mkFormatter"
              valueAlias="订单数"
              id="order-trend"
            >
              <template slot="header">
                <div class="trend-title">订单数</div>
                <div>
                  <span class="trend-value">{{
                    chartData | nullsafe("order.value", 0) | shortNumber
                  }}</span
                  ><span class="trend-mom"
                    >MoM<span
                      :class="chartData.order.percentage > 0 ? 'up' : 'down'"
                    >
                      {{
                        chartData | nullsafe("order.percentage", 0) | percent
                      }}</span
                    ></span
                  >
                </div>
              </template>
            </area-chart></a-col
          >
        </a-row>
      </a-card>
    </a-row>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
  
import ChartCard from "@/components/Charts/ChartCard";
import CityCard from "./CityCard";
import ColumnChart from "./ColumnChart";
import AreaChart from "./AreaChart";
import Trend from "@/components/Charts/Trend";
import Bus from "@/bus";

const trendMetrics = ["closeWon", "churn", "gmv", "order"];
const defaultChartData = [{}, ...trendMetrics].reduce((v, k) => {
  v[k] = {};
  return v;
});

const mkFormatter = val => {
  if (val >= 1000000) {
    return `${(val / 1000000).toFixed(1)}m`;
  } else if (val >= 1000) {
    return `${(val / 1000).toFixed(1)}k`;
  } else {
    return val;
  }
};

export default {
  name: "Dashboard",
  components: {
    ChartCard,
    CityCard,
    ColumnChart,
    AreaChart,
    Trend
  },
  data() {
    return {
      loading: true,
      chartLoading: true,
      chartData: defaultChartData,
      data: { citysCoreMetricVOList: [] },
      trendUnit: "Week",
      mkFormatter: mkFormatter
    };
  },
  created() {
    this.loadData();
    Bus.$on("getTarget", this.loadData);
  },
  beforeDestroy() {
    Bus.$off("getTarget", this.loadData);
  },
  methods: {
    onTrendUnitChange(e) {
      this.trendUnit = e.target.value;
      this.loadChartData();
    },
    loadChartData() {
      this.chartLoading = true;
      axios({
        url:   this.$baseUrl + "coreBusinessMetric/chartData",
        params: { period: this.trendUnit.toLowerCase() },
        method: "GET"
      }).then(res => {
        if (res.data.success === true) {
          const obj = res.data.obj;
          trendMetrics.forEach(m => {
            this.chartData[m].data = obj[`${m}By${this.trendUnit}`].mapList;
            this.chartData[m].value = obj[`${m}By${this.trendUnit}`].value;
            this.chartData[m].percentage =
              obj[`${m}By${this.trendUnit}`].percentage;
          });
          this.chartLoading = false;
        }
      });
    },
    loadData() {
      this.loadChartData();
      this.loading = true;
      axios({
        url:   this.$baseUrl + "coreBusinessMetric/kitchenOccupancy",
        method: "GET"
      }).then(res => {
        if (res.data.success === true) {
          this.data = res.data.obj;
          this.loading = false;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.footer-left {
  flex-grow: 1;
}

.trend-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
}

.trend-value {
  font-size: 42px;
  font-weight: 800;
}

.trend-mom {
  font-size: 18px;
  font-weight: 400;
  margin-left: 64px;
  .up {
    color: rgb(126, 181, 155);
  }

  .down {
    color: rgb(228, 123, 123);
  }
}
</style>
